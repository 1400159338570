<template>
    <div class="search-c">
        <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
            <el-form-item label="" props="phone" v-if="is_phone">
                <el-input size="small" v-model="params.phone" prefix-icon="el-icon-search" placeholder="请输入手机号"
                    @change="search" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="" props="name" v-if="is_name">
                <el-input size="small" v-model="params.name" prefix-icon="el-icon-search" placeholder="请输入用户名称/ID"
                    @change="search" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="" props="agancy_name" v-if="is_agancy_name">
                <el-input size="small" v-model="params.agancy_name" prefix-icon="el-icon-search" placeholder="请输入代理名称/ID"
                    @change="search" :clearable="true"></el-input>
            </el-form-item>

            <el-form-item label="" props="identity_type" v-if="is_identity">
                <el-select v-model="params.identity_type" placeholder="选择用户身份类型" @change="search" :clearable="true">
                    <el-option v-for="item in user_identity_list" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="user_type" v-if="is_user_type">
                <el-select v-model="params.user_type" placeholder="请选择用户类型" @change="search" :clearable="true">
                    <el-option v-for="item in user_type_list" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="proxy_audit_status" v-if="is_proxy_audit_status">
                <el-select v-model="params.proxy_audit_status" placeholder="请选择审核状态" @change="search" :clearable="true">
                    <el-option v-for="item in proxy_audit_status_list" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="kol_audit_status" v-if="is_kol_audit_status">
                <el-select v-model="params.kol_audit_status" placeholder="请选择审核状态" @change="search" :clearable="true">
                    <el-option v-for="item in kol_audit_status_list" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="agancy_phone" v-if="is_agancy_phone">
                <el-input size="small" v-model="params.agancy_phone" prefix-icon="el-icon-search" placeholder="请输入所属代理商手机号"
                    @change="search"></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-right: 100px;" v-if="is_time_field">
                <div class="flex_box">
                    <div class="time_type">
                        <el-select size='small' v-model="params.time_field" placeholder="请选择" @change="search"
                            :clearable="false">
                            <el-option v-for="item in time_field_list" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="time_date">
                        <el-date-picker style="width:100%" size='small' v-model="params.date" type="daterange"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                            @change="search" :clearable="true">
                        </el-date-picker>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="" props="order_num" v-if="is_order_num">
                <el-input size="small" v-model="params.order_num" @change="search" prefix-icon="el-icon-search"
                    placeholder="请输入订单号" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="" props="custom_phone" v-if="is_custom_phone">
                <el-input size="small" v-model="params.custom_phone" @change="search" :clearable="true"
                    prefix-icon="el-icon-search" placeholder="请输入客户手机号"></el-input>
            </el-form-item>
            <el-form-item label="" props="custom_name" v-if="is_custom_name">
                <el-input size="small" v-model="params.custom_name" @change="search" :clearable="true"
                    prefix-icon="el-icon-search" placeholder="请输入客户名称/ID"></el-input>
            </el-form-item>
            <el-form-item label="" props="corp_name" v-if="is_corp_name">
                <el-input size="small" v-model="params.corp_name" @change="search" prefix-icon="el-icon-search"
                    placeholder="请输入名称" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="" props="corp_phone" v-if="is_corp_phone">
                <el-input size="small" v-model="params.corp_phone" @change="search" prefix-icon="el-icon-search"
                    placeholder="请输入登录手机号" :clearable="true"></el-input>
            </el-form-item>

            <el-form-item label="" props="recharge_source" v-if="is_recharge_source">
                <el-select v-model="params.recharge_source" placeholder="请选择充值来源" @change="search" :clearable="true">
                    <el-option v-for="item in recharge_source_list" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="category" v-if="is_category">
                <el-select v-model="params.category" placeholder="请选择商品类别" @change="search" :clearable="true">
                    <el-option v-for="item in category_list" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" props="order_source" v-if="is_order_source">
                <el-select v-model="params.order_source" placeholder="请选择订单来源" @change="search" :clearable="true">
                    <el-option v-for="item in order_source_list" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="交易时间" props="busy_time" style="margin-right: 100px;" v-if="is_busy_time">
                <div class="time_date">
                    <el-date-picker style="width:100%" size='small' v-model="params.busy_time" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                        @change="search" :clearable="true">
                    </el-date-picker>
                </div>
            </el-form-item>
        </el-form>
        <el-button v-if="is_export" size="small" class="leading-out" icon="el-icon-upload2" @click="exportBtn">导出</el-button>
    </div>
</template>
<script>
export default {
    props: {
        is_export: {
            type: Boolean,
            default: false
        },
        is_phone: {
            type: Boolean,
            default: false
        },
        is_name: {
            type: Boolean,
            default: false
        },
        is_agancy_name: {
            type: Boolean,
            default: false
        },
        is_agancy_phone: {
            type: Boolean,
            default: false
        },
        is_identity: {
            type: Boolean,
            default: false
        },
        is_user_type: {
            type: Boolean,
            default: false
        },
        is_time_field: {
            type: Boolean,
            default: false
        },
        is_proxy_audit_status: {
            type: Boolean,
            default: false
        },
        is_kol_audit_status: {
            type: Boolean,
            default: false
        },
        is_custom_phone: {
            type: Boolean,
            default: false
        },
        is_custom_name: {
            type: Boolean,
            default: false
        },
        is_order_num: {
            type: Boolean,
            default: false
        },
        is_corp_name: {
            type: Boolean,
            default: false
        },
        is_corp_phone: {
            type: Boolean,
            default: false
        },
        is_recharge_source: {
            type: Boolean,
            default: false
        },
        is_category: {
            type: Boolean,
            default: false
        },
        is_order_source: {
            type: Boolean,
            default: false
        },
        is_busy_time: {
            type: Boolean,
            default: false
        },
        time_field_list: {
            type: Array,
            default: () => []
        },
        proxy_audit_status_list: {
            type: Array,
            default: () => []
        },
        user_identity_list: {
            type: Array,
            default: () => []
        },
        user_type_list: {
            type: Array,
            default: () => []
        },
        kol_audit_status_list: {
            type: Array,
            default: () => []
        },
        recharge_source_list: {
            type: Array,
            default: () => []
        },
        category_list: {
            type: Array,
            default: () => []
        },
        order_source_list: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            params: {
              time_field:'',
            },
        }
    },
    created() {
    },
    watch: {
        'time_field_list': {
            handler: function(nVal, oVal) {
              // console.log(nVal)
                if (nVal) {
                    this.params.time_field = nVal[0].value
                }
            },
            deep: true,
        }
    },
    methods: {
        search() {
            // console.log(this.params)
            this.$emit('search', this.params)
        },
        resetFrom() {
            for (let prop in this.params) {    // 注意区分 for...of 的区别,for...of是遍历对象中的值
                delete this.params[prop];// 这里注意：如果对象属性是变量，不可用"."的格式处理访问
            }
        },
        // 导出
        exportBtn(){
          this.$emit('export', this.params)
        },
    }
}
</script>
<style lang="scss" scoped>
.search-c {
    display: flex;
    position: relative;

    .input-box {
        width: 160px;
    }

    .leading-out {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.flex_box {
    display: flex;
    align-items: center;
}

.time_type {
    width: 148px;
    height: 32px;

    ::v-deep .el-input__inner {
        background: #F4F4F4;
        border-radius: 4px 0 0 4px;
        border: none;
        width: 148px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;

    }
}

.time_date {
    width: 312px;
    height: 32px;

    .el-input__inner {
        border-radius: 0 4px 4px 0;
        // border: 1px solid #dbdbdb;

    }

    .el-date-editor {
        width: 100%;
    }
}
</style>
