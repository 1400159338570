<template>
    <div class='subscribe_details zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="962px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">订阅详情</div>
            </div>
            <div class="details_info scroll_bar_x">
                <el-table :data="tableData" v-loading="loading" :header-cell-style="headerCellStyle" style="width: 100%">
                    <el-table-column prop="subscribe_name" label="订阅套餐类型"> </el-table-column>
                    <el-table-column prop="" label="订阅有效期">
                        <template slot-scope="scope">
                            <div>{{ scope.row.subscribe_started }}/{{ scope.row.subscribe_ended }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="subscribe_status_text" label="订阅状态"> </el-table-column>
                    <el-table-column prop="subscribe_time" label="购买时间">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {  },
    props: {},
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            headerCellStyle: {
                background: '#F0F2F5',
                color: '#333',
                fontWeight: 500,
                fontSize: '14px'
            },
            id: '',
            loading: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(info) {
            this.dialogVisible = true
            this.tableData = []
            this.getDetails(info)
        },
        getDetails(info) {
            this.tableData.push(info)
        },
    },
}
</script>

<style lang='scss' scoped>
.subscribe_details {
    .details_info {
        width: 100%;
        max-height: 500px;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        overflow-y: auto;
    }
}

</style>
